<template>
  <div id="home">
    <BannerGallery />
    <div class="relative sm:hidden">
      <TeamEventRequestButton />
    </div>
    <div v-if="lastViewedEvents?.length > 0">
      <div class="mt-16">
        <ProductShowcase
          data-test-id="last-viewed-events-section"
          :loading="loading"
          :products="lastViewedEvents"
          is-mobile-slider
          is-desktop-accordeon
          image-loading="lazy"
          :gtm-item-list-id="GtmItemListId.GeneralPageLastViewedEvents"
          :tags="[]"
          :title-h-class="`legacy-h2`"
          :title="t('home.lastViewedEvents')"
          algolia-insights-label="Last Viewed Events - Homepage"
        />
      </div>
    </div>

    <template
      v-for="(section, index) in events"
      :key="'homepage-events-' + index"
    >
      <div :class="index === 0 ? 'mt-16' : 'mt-8 md:mt-16'">
        <ProductShowcase
          :loading="loading"
          v-bind="section"
          is-mobile-slider
          is-desktop-accordeon
          image-loading="lazy"
          :tags="[]"
          :title-h-class="`legacy-h2`"
        />
      </div>

      <template v-if="index === 'promotions'">
        <CardsSection
          v-if="cardsSectionContent"
          class="mt-16"
          :content="cardsSectionContent"
        />

        <ProductGiftcardEventsShowcase :title="t('home.inspirationForYou')" />
      </template>
    </template>

    <Grid v-if="gridSectionContent" :content="gridSectionContent" />

    <Carousel
      v-if="carousel"
      class="!overflow-visible !w-full"
      :content="carousel"
    />
  </div>
</template>
<script setup lang="ts">
import organizationSchema from '~/constants/organizationSchema'
import { useHomepageAlgolia } from '~/composables/useHomepageAlgolia'
import { useLastViewedEvents } from '~/composables/useLastViewedEvents'
import { GtmItemListId } from '~/composables/useKftGtm/types'
import { useAlgoliaSearchInsights } from '~/composables/algolia/useAlgoliaSearchInsights'

const { t, locale } = useI18n()
const currentLocale: string = locale.value
const { client: insightsClient } = useAlgoliaSearchInsights()

const { data: homePageData } = await useStrapiSingleType(
  `home-page?locale=${locale.value}&populate=deep,4`
)

provide('homePageGalleryData', homePageData)

const cardsSectionContent = computed(
  () => homePageData.value?.data?.attributes?.cardSection
)

const gridSectionContent = computed(
  () => homePageData.value?.data?.attributes?.gridSection
)

const carousel = computed(() => homePageData.value?.data?.attributes?.carousel)

const { algoliaResults, pending: loading } = await useHomepageAlgolia()

const { lastViewedEvents } = useLastViewedEvents(true)

const filteredGiftIdeas = computed(() => {
  if (!algoliaResults?.value?.results) return []

  return algoliaResults?.value?.results?.[2]?.hits
    .filter(
      (event) =>
        !events?.value?.topEvents?.products
          .map((topEvent) => topEvent.objectID)
          .includes(event.objectID)
    )
    .slice(0, 8)
})

const events = computed(() => {
  if (!algoliaResults?.value?.results) return {}

  const results = algoliaResults?.value?.results
  if (!results) return {}

  return {
    topEvents: {
      title: t('home.browseTopEvents'),
      'data-test-id': 'top-events-section',
      gtmItemListId: GtmItemListId.GeneralPageTopEvents,
      algoliaInsightsClient: insightsClient,
      algoliaInsightsLabel: 'Top Events - Homepage',
      products: results[0]?.hits,
      queryId: results[0]?.queryID,
    },
    promotions: {
      title: t('home.promotionEventsTitle'),
      'data-test-id': 'promotion-events-section',
      algoliaInsightsLabel: 'Promotions - Homepage',
      products: results[3]?.hits,
      queryId: results[3]?.queryID,
    },
    kits: {
      title: t('home.kitsTitle'),
      'data-test-id': 'kit-events-section',
      algoliaInsightsLabel: 'Kits - Homepage',
      products: results[4]?.hits,
      queryId: results[4]?.queryID,
    },
    topGiftIdeasEvents: {
      title: t('home.recommendedForYou'),
      'data-test-id': 'recommended-events-section',
      gtmItemListId: GtmItemListId.GeneralPageTopGiftIdeas,
      algoliaInsightsClient: insightsClient,
      algoliaInsightsLabel: 'Gift Ideas For You - Homepage',
      products: filteredGiftIdeas.value,
      queryId: results[2]?.queryID,
    },
    newEvents: {
      title: t('home.newEventsForYou'),
      'data-test-id': 'new-events-section',
      gtmItemListId: GtmItemListId.GeneralPageNewEvents,
      algoliaInsightsClient: insightsClient,
      algoliaInsightsLabel: 'New Events - Homepage',
      products: results[1]?.hits,
      queryId: results[1]?.queryID,
    },
  }
})

useHead({
  script: [
    {
      type: 'application/ld+json',
      innerHTML: organizationSchema[currentLocale],
    },
  ],
})

definePageMeta({
  preloaderType: 'home',
})
</script>
